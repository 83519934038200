<template>
  <b-card>

    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
         
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <span class="card-text">{{ userData.userName }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-users-edit', params: { id: userData._id } }"
                variant="primary"
              >
                Edit
              </b-button>
            </div>
          </div>
        </div>

      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">First Name</span>
            </th>
            <td class="pb-50">
              {{ userData.firstName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Last Name</span>
            </th>
            <td class="pb-50">
              {{ userData.lastName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">User Name</span>
            </th>
            <td class="pb-50">
              {{ userData.userName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50">
              {{ userData.role }}
            </td>
          </tr>
          
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Client</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientName(userData.clients) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.status }}
            </td>
          </tr>
         
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Roles allowed</span>
            </th>
          </tr>

          <tr
            v-for="item in userData.rolesAllowed"
            :key="item"
          >
            <th class="pb-50">
              
            </th>
            <td class="pb-50">
              {{ roleName(item) }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">User admin</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.userAdmin }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Level</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.level }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Upline</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ uplineName(userData.upLine) }}
            </td>
          </tr>

        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { ref, onUnmounted } from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'
import store from '@/store'
import roleStoreModule from '../../../pages/roles/roleStoreModule'
import clientStoreModule from '../../../pages/clients/clientStoreModule'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
     const ROLE_APP_STORE_MODULE_NAME = 'app-role'
     const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
     const USER_APP_STORE_MODULE_NAME = 'app-user'
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule)
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const roles = ref([])
     store.dispatch('app-role/fetchRoles')
      .then(response => { 
        roles.value = response.data.roles})

    const clients = ref([])
    store.dispatch('app-client/fetchClients')
      .then(response => { 
        clients.value = response.data.items})

    const users = ref([])
    store.dispatch('app-user/fetchUsersAll')
      .then(response => { 
        users.value = response.data.users
    })

    const { resolveUserRoleVariant } = useUsersList()

    return {
      avatarText,
      resolveUserRoleVariant,
      roles,
      clients,
      users
    }
  },
  methods: {
    roleName(id) {
      const found = this.roles.find(element => element._id == id)
      if (found) {
        return found.name
      }
    },  
    clientName(id) {
      const client = this.clients.find(element => element._id == id)
      if (client) {
        return client.name
      }
    },  
    uplineName(id) {
      const upline = this.users.find(element => element._id == id)
      if (upline) {
        return upline.userName
      }
    },  
  }
}
</script>

<style>

</style>
